import React from 'react';

const NoMatch: React.FC = () => {
  return (
    <>
        <div>404 Page Not Found 😔</div>
    </>
      );
};

export default NoMatch;